import React from "react"

import Layout from "../components/theme/theme"
import Logo from '../assets/svg/logo.svg';

import "../components/sections/section/section.css"
import "../components/sections/section/circle-list.css"

const IndexPage = props => {

  return (
    <Layout>
      <section className="sec-Section">
        <div className="sec-Section_Inner lyt-Container">
          <div className="sec-Section_Body">
            <div className="sec-Section_Logo">
              <Logo />
            </div>

            <h2 className="sec-Section_Title">
              Website Coming Soon
            </h2>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage